





















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Component({ components: {} })
export default class Table extends Vue {
    @Prop() headers;
    @Prop() tableData;

    @Watch('tableData')
    check(){
        // console.log(this.tableData);
    }
}
